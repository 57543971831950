import { FETCH_CART, SET_CART, ERROR_CART, UPDATE_CART, UPDATE_CART_DATA, QUANTITY_LOADING, CLEAR_CART } from './types'
import { GetData, PostData, decryptPrice } from '../../helpers/apiHandler'
import toastr from 'toastr'

toastr.options = {
  closeButton: true,
  showDuration: 150,
  hideDuration: 500,
  timeOut: 1000
}

export const fetchCartItems = (locationId) => async (dispatch) => {
  try {
    const checkStaff = JSON.parse(localStorage.getItem("profile"))?.isStaff;
    dispatch({ type: FETCH_CART })
    const { result, status, message } = await GetData(`customer/getCustomerCart?customerLocationId=${locationId}`)
    if (status) {
            // If user is staff then decrypt the sell_price
            const updatedResult = checkStaff
            ? result.map(item => ({
                ...item,
                sell_price: decryptPrice(item.sell_price),
                staffPriceData: item.staffPriceData
                ? {
                    ...item.staffPriceData,
                    staff_price: checkStaff ? decryptPrice(item.staffPriceData.staff_price) : item.staffPriceData.staff_price
                  }
                : null
              }))
            : result;
      dispatch({ type: SET_CART, payload: updatedResult })
    } else {
      dispatch({ type: ERROR_CART })
      toastr.error(message, 'Error')
    }
  } catch (err) {
    dispatch({ type: ERROR_CART })
    toastr.error('Error while fetching cart items')
  }
}

export const updateCartItems = (values) => async (dispatch) => {
  try {
    dispatch({ type: QUANTITY_LOADING })
    const { status, message, result } = await PostData('customer/updateCustomerCart', values)
    if (status) {
      dispatch(updateCartProducts(values))
      dispatch({ type: UPDATE_CART, payload: result })
      dispatch(fetchCartItems(JSON.parse(localStorage.getItem('selectedLocation'))?.id))
      toastr.success(message, 'Success')
    } else {
      dispatch({ type: ERROR_CART })
      toastr.error(message, 'Error')
    }
  } catch (err) {
    dispatch({ type: ERROR_CART })
    toastr.error('Error while fetching cart items')
  }
}

export const multipleCartItems = (values) => async (dispatch) => {
  let countProductsFlag = 0
  try {
    for (let i = 0; i < values.length; i++) {
      dispatch({ type: QUANTITY_LOADING })
      const { status, result } = await PostData('customer/updateCustomerCart', values[i])
      if (status) {
        dispatch(updateCartProducts(values[i]))
        dispatch({ type: UPDATE_CART, payload: result })
        countProductsFlag += 1
      } else {
        dispatch({ type: ERROR_CART })
      }
    }
    if (countProductsFlag === values.length) {
      toastr.success('All Products added to cart', 'Success')
    } else {
      toastr.error('Some products couldn\'t be added', 'Success')
    }
  } catch (err) {
    dispatch({ type: ERROR_CART })
    toastr.error('Error while fetching cart items')
  }
}

export const updateCartProducts = values => dispatch => {
  dispatch({ type: UPDATE_CART_DATA, payload: values })
}

export const clearCartItems = () => dispatch => {
  dispatch({ type: CLEAR_CART })
}
