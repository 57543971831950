import axios from 'axios'
import { globalVariable } from '../global'
import CryptoJS from 'crypto-js'

export const checkLoggedIn = () => {
  if (localStorage.token) {
    return true
  } else {
    return false
  }
}

export const GetSearchList = async (data) => {
  return await axios
    .post(process.env.REACT_APP_EXTERNAL_SEARCH_API, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_EXTERNAL_SEARCH_APIKEY
      }
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response) {
        return error?.response?.data
      } else {
        return error
      }
    })
}

export const PostWithoutToken = async (url, data) => {
  return await axios
    .post(globalVariable.baseURL + url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(function (response) {
      if (response?.headers?.access_token) {
        localStorage.setItem('token', response?.headers?.access_token)
      }

      return response.data
    })
    .catch(function (error) {
      if (error?.response?.data) {
        return error.response.data
      } else {
        return error
      }
    })
}

export const GetWithoutToken = async (url) => {
  return await axios
    .get(globalVariable.baseURL + url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.data) {
        return error.response.data
      } else {
        return error
      }
    })
}

export const verifyTokenExpired = async (url, data) => {
  return await axios
    .post(globalVariable.baseURL + url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.data) {
        return error.response.data
      } else {
        return error
      }
    })
}

export const PostData = async (url, data) => {
  return await axios
    .post(globalVariable.baseURL + url, data, {
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.token
      }
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        setTimeout(() => {
          localStorage.removeItem('profile')
          localStorage.removeItem('token')
          localStorage.removeItem('selectedLocation')
          window.location.href = '/'
        }, 1000)
      }
      return error.response.data
    })
}

export const GetData = async (url) => {
  return await axios
    .get(globalVariable.baseURL + url, {
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.token
      }
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response) {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            localStorage.removeItem('profile')
            localStorage.removeItem('token')
            localStorage.removeItem('selectedLocation')
            window.location.href = '/'
          }, 1000)
        }
        return error?.response?.data
      } else {
        return error
      }
    })
}

export const PutData = async (url, formData) => {
  return await axios
    .put(globalVariable.baseURL + url, formData, {
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.token
      }
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response) {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            localStorage.removeItem('profile')
            localStorage.removeItem('token')
            localStorage.removeItem('selectedLocation')
            window.location.href = '/'
          }, 1000)
        }
        return error?.response?.data
      } else {
        return error
      }
    })
}

export const DeleteData = async (url, data) => {
  return await axios
    .delete(globalVariable.baseURL + url, {
      headers: {
        'Content-Type': 'application/json',
        access_token: localStorage.token
      }
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        setTimeout(() => {
          localStorage.removeItem('profile')
          localStorage.removeItem('token')
          localStorage.removeItem('selectedLocation')
          window.location.href = '/'
        }, 1000)
      }
      return error.response.data
    })
}

export const decryptPrice = (text) => {
  let textParts = text.split(':');
  let iv = CryptoJS.enc.Hex.parse(textParts.shift());
  let encryptedText = CryptoJS.enc.Hex.parse(textParts.join(':'));
  let decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedText },
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const shouldShowPrice = () => {
  const isStaff = JSON.parse(localStorage.getItem("profile"))?.isStaff;
  const priceEnabled = JSON.parse(localStorage.getItem("profile"))?.priceEnabled;
  if (isStaff && !priceEnabled) {
      return false;
  }
  return true;
}

// Function to get the price
export const getPrice = (staffPriceData, sellPrice) => {
  return (staffPriceData?.staff_price && parseFloat(staffPriceData?.staff_price) > 0)
    ? parseFloat(staffPriceData.staff_price)
    : sellPrice;
};

// Function to get the inventory quantity
export const getInventoryQty = (staffPriceData, inventoryQty) => {
  return (staffPriceData?.max_qty && parseInt(staffPriceData?.max_qty) > 0)
  ? parseInt(staffPriceData.max_qty)
  : inventoryQty;
  // return staffPriceData?.max_qty ?? inventoryQty;
};